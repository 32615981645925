import RJSON from 'relaxed-json';
import { getHashConfig } from '.';

const HASH_PARAMETERS_KEYS = ['country', 'place_from', 'place_to', 'selected_class'];

function removeHashParameters(config) {
  Object.keys(config).forEach((key) => {
    if (HASH_PARAMETERS_KEYS.includes(key)) {
      delete config[key];
    }
  });
}

function getAndRemoveQueryParamValue(url, param) {
  const value = url.searchParams.get(param);
  if (value) {
    url.searchParams.delete(param);
  }

  return value;
}

export default function ({ element, id }) {
  if (element && element.innerHTML) {
    const json = element.innerHTML;
    const userConfig = RJSON.parse(json.substring(json.indexOf('{'), json.lastIndexOf('}') + 1));
    const hashConfig = getHashConfig();
    const isHashPresent = !!Object.values(hashConfig).length;

    if (isHashPresent) {
      removeHashParameters(userConfig);
    }

    const url = new URL(window.location.href);
    userConfig.preset_url_query = getAndRemoveQueryParamValue(url, `kwq${id}`);
    userConfig.booking_token = getAndRemoveQueryParamValue(url, `kwt${id}`);
    userConfig.error_code = getAndRemoveQueryParamValue(url, `kwe${id}`);
    userConfig.error_message = getAndRemoveQueryParamValue(url, `kwem${id}`);
    url.searchParams.delete(`kwn${id}`);
    userConfig.top_page_url = url.toString();

    window.history.replaceState(null, null, userConfig.top_page_url);

    return {
      ...userConfig,
      ...hashConfig,
      refx2s6d: userConfig.top_page_url,
      id,
    };
  }

  return {};
}
