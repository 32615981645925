const hashParameters = {
  country: 'country',
  from: 'place_from',
  to: 'place_to',
  by: 'selected_class',
  'payment-success': 'booking_token',
  'payment-failure': 'booking_token',
};
const hashKeys = Object.keys(hashParameters);

export default function () {
  const hash = window.location.hash.substring(1);
  const list = hash.split('/').filter(item => item);

  return list.reduce((config, item, i) => {
    const isKey = hashKeys.includes(item);
    const nextItem = list[i + 1];
    const nextIsNotKey = !hashKeys.includes(nextItem);

    if (isKey && nextItem && nextIsNotKey) {
      const parameter = hashParameters[item];
      config[parameter] = nextItem.split('+').join(' ');
    }

    // исключительная ситуация получения кода ошибки
    if (item === 'payment-failure') {
      config.error_code = list[i + 2];
    }

    return config;
  }, {});
}
