import VueScrollTo from 'vue-scrollto';
import { getScrollParent } from '.';

export default function (data, iframe, offset = 0) {
  const container = getScrollParent(iframe);

  VueScrollTo.scrollTo(iframe, data.duration, {
    offset: data.y + offset,
    container,
  });
}
