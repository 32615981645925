export default function (data) {
  const hash = [];
  const { error } = data;

  function checkParameter(name) {
    const value = data[name];

    if (value) {
      hash.push(name, value.split(' ').join('+'));
    }
  }

  checkParameter('country');
  checkParameter('from');
  checkParameter('to');
  checkParameter('by');
  checkParameter('payment-success');
  checkParameter('payment-failure');

  if (error) {
    hash.push(error.code, error.message);
  }

  window.history.replaceState(null, null, `#/${hash.join('/')}`);
}
