import iFrameResize from 'iframe-resizer/js/iframeResizer';
import {
  addEvents, getConfig, getIframeElement,
} from '.';

const IFRAME_RESIZE_OPTIONS = {
  checkOrigin: false,
};

let id = 0;

export function resetWidgetIdCounter() {
  id = 0;
}

export default function (context) {
  id += 1;

  const element = context.querySelector('[data-kiwitaxi-white-label-element]');
  const baseUrl = element.dataset.baseUrl || 'https://widget-white-label.kiwitaxi.com/';

  const config = getConfig({
    element: context.querySelector('[data-kiwitaxi-white-label-config]'),
    id,
  });

  const iframe = getIframeElement(config, baseUrl);

  element.innerHTML = '';
  element.appendChild(iframe);
  addEvents({ iframe, config, id });

  if (!config.max_height) {
    iFrameResize(IFRAME_RESIZE_OPTIONS, iframe);
  }
}
