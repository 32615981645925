export default function (config, baseUrl) {
  const iframe = document.createElement('iframe');
  const { style } = iframe;

  style.width = '1px';
  style.minWidth = '100%';
  style.minHeight = '400px';
  style.border = 'none';
  style.display = 'block';
  style.height = `${config.max_height}px`;

  iframe.scrolling = 'auto';
  iframe.src = baseUrl;

  return iframe;
}
