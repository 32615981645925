import { scrollTo, updateHash } from '.';

export default function ({ iframe, config, id }) {
  function updateTopPageUrl(iframe) {
    if (iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        type: 'update-top-page-url',
        data: window.location.href,
      }, '*');
    }
  }

  window.addEventListener('message', (e) => {
    const { type, data, id: receivedId } = e.data || {};

    if (type === 'load' && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        type: 'update',
        data: config,
      }, '*');
    }

    if (receivedId !== id) {
      return;
    }

    if (type === 'request-redirect-to') {
      window.location.href = data;
      return;
    }

    if (type === 'scroll-to' && !config.max_height) {
      scrollTo(data, iframe, config.scroll_offset);
    }

    if (type === 'change-parameters' && config.deep_link) {
      updateHash(data);
      updateTopPageUrl(iframe);
    }

    if (type === 'set-view' && config.deep_link) {
      updateHash(data);
      updateTopPageUrl(iframe);
    }
  });
}
