import { initWidget, resetWidgetIdCounter } from './utils';

function initWidgetsBySelector(selector) {
  const contexts = document.querySelectorAll(selector);
  resetWidgetIdCounter();

  [].forEach.call(contexts, initWidget);
}

initWidgetsBySelector('[data-kiwitaxi-white-label]');
window.initKiwitaxiWhiteLabel = initWidgetsBySelector;
